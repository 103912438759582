// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-labs-game-species-test-tsx": () => import("./../../../src/pages/labs/game-species-test.tsx" /* webpackChunkName: "component---src-pages-labs-game-species-test-tsx" */),
  "component---src-pages-labs-home-target-tsx": () => import("./../../../src/pages/labs/home-target.tsx" /* webpackChunkName: "component---src-pages-labs-home-target-tsx" */),
  "component---src-pages-labs-index-tsx": () => import("./../../../src/pages/labs/index.tsx" /* webpackChunkName: "component---src-pages-labs-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */)
}

