import img001_001 from "./img/001_001.jpg"
import img002_001 from "./img/002_001.jpg"
import img003_001 from "./img/003_001.jpg"
import img004_001 from "./img/004_001.jpg"
import img005_001 from "./img/005_001.jpg"
import img006_001 from "./img/006_001.jpg"
import img007_001 from "./img/007_001.jpg"
import img008_001 from "./img/008_001.jpg"
import img009_001 from "./img/009_001.jpg"
import img010_001 from "./img/010_001.jpg"
import img011_001 from "./img/011_001.jpg"
import img012_001 from "./img/012_001.jpg"
import img013_001 from "./img/013_001.jpg"
import img014_001 from "./img/014_001.jpg"
import img015_001 from "./img/015_001.jpg"
import img016_001 from "./img/016_001.jpg"
import img017_001 from "./img/017_001.jpg"
import img019_001 from "./img/019_001.jpg"
import img020_001 from "./img/020_001.jpg"
import img021_001 from "./img/021_001.jpg"
import img022_001 from "./img/022_001.jpg"
import img023_001 from "./img/023_001.jpg"
import img024_001 from "./img/024_001.jpg"
import img025_001 from "./img/025_001.jpg"
import img026_001 from "./img/026_001.jpg"
import img027_001 from "./img/027_001.jpg"
import img028_001 from "./img/028_001.jpg"
import img029_001 from "./img/029_001.jpg"
import img101_001 from "./img/101_001.jpg"
import img102_001 from "./img/102_001.jpg"
import img103_001 from "./img/103_001.jpg"
import img104_001 from "./img/104_001.jpg"
import img105_001 from "./img/105_001.jpg"
import img106_001 from "./img/106_001.jpg"
import img107_001 from "./img/107_001.jpg"
import img108_001 from "./img/108_001.jpg"
import img109_001 from "./img/109_001.jpg"
import img110_001 from "./img/110_001.jpg"
import img111_001 from "./img/111_001.jpg"
import img112_001 from "./img/112_001.jpg"
import img113_001 from "./img/113_001.jpg"
import img114_001 from "./img/114_001.jpg"
import img115_001 from "./img/115_001.jpg"
import img116_001 from "./img/116_001.jpg"

export interface Quiz {
  image: string
  answer: string
}
export const quizList: Array<Quiz> = [
  { image: img001_001, answer: "マガモ" },
  { image: img002_001, answer: "カルガモ" },
  { image: img003_001, answer: "ヨシガモ" },
  { image: img004_001, answer: "コガモ" },
  { image: img005_001, answer: "オナガガモ" },
  { image: img006_001, answer: "ヒドリガモ" },
  { image: img007_001, answer: "ハシビロガモ" },
  { image: img008_001, answer: "ホシハジロ" },
  { image: img009_001, answer: "スズガモ" },
  { image: img010_001, answer: "キンクロハジロ" },
  { image: img011_001, answer: "クロガモ" },
  { image: img012_001, answer: "ゴイサギ" },
  { image: img013_001, answer: "バン" },
  { image: img014_001, answer: "タシギ" },
  { image: img015_001, answer: "ヤマシギ" },
  { image: img016_001, answer: "カワウ" },
  { image: img017_001, answer: "エゾライチョウ" },
  { image: img019_001, answer: "コジュケイ" },
  { image: img020_001, answer: "ヤマドリ" },
  { image: img021_001, answer: "キジ" },
  { image: img022_001, answer: "キジバト" },
  { image: img023_001, answer: "ニュウナイスズメ" },
  { image: img024_001, answer: "スズメ" },
  { image: img025_001, answer: "ヒヨドリ" },
  { image: img026_001, answer: "ムクドリ" },
  { image: img027_001, answer: "ミヤマガラス" },
  { image: img028_001, answer: "ハシボソガラス" },
  { image: img029_001, answer: "ハシブトガラス" },
  { image: img101_001, answer: "ノウサギ・ユキウサギ" },
  { image: img102_001, answer: "タイワンリス" },
  { image: img103_001, answer: "シマリス" },
  { image: img104_001, answer: "ヌートリア" },
  { image: img105_001, answer: "ヒグマ" },
  { image: img106_001, answer: "ツキノワグマ" },
  { image: img107_001, answer: "イノシシ" },
  { image: img108_001, answer: "ニホンジカ" },
  { image: img109_001, answer: "アライグマ" },
  { image: img110_001, answer: "タヌキ" },
  { image: img111_001, answer: "キツネ" },
  { image: img112_001, answer: "テン" },
  { image: img113_001, answer: "イタチ（オス）" },
  { image: img114_001, answer: "ミンク" },
  { image: img115_001, answer: "アナグマ" },
  { image: img116_001, answer: "ハクビシン" },
]
