import {
  Request,
  Response,
  ContactMessageGateway,
} from "../../../use-case/contact-message-send"
import * as firebase from "firebase/app"
import "firebase/functions"
import "firebase/analytics"

export class WebApiContactMessageGateway implements ContactMessageGateway {
  async write(req: Request): Promise<Response> {
    try {
      if (!firebase.apps.length) {
        firebase.initializeApp({
          apiKey: "AIzaSyAR2Va_9uNO32bAB4HXLHL6sgDCtnRy8gY",
          authDomain: "nilay-about.firebaseapp.com",
          databaseURL: "https://nilay-about.firebaseio.com",
          projectId: "nilay-about",
          storageBucket: "nilay-about.appspot.com",
          messagingSenderId: "501712650959",
          appId: "1:501712650959:web:191a29b977cad3f2472dba",
          measurementId: "G-C3KJX5WQEM",
        })
        firebase.analytics()
      }

      const sendContactMessage: firebase.functions.HttpsCallable = firebase
        .functions()
        .httpsCallable("sendContactMessage")

      const result = await sendContactMessage(req)

      const data: Response = {
        hasError: result.data.hasError,
        errorMessage: result.data.errorMessage,
        uuid: result.data.uuid,
      }

      return data
    } catch (ex) {
      throw ex
    }
  }
}
