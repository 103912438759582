import React from "react"
import GameSpiciesTest from "../../view/labs/game-species-test"

type Props = {}

const Component: React.FC<Props> = (props: Props) => {
  return <GameSpiciesTest />
}

export default Component
